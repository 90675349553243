import React, { useState, useContext, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import '../style/index.css';
import { Login } from "./Login";
import { ScrollToTop } from "./ScrollToTop";
import { TabComponents } from './TabComponents';
//ローカルの画像を表示させるためのimport
import LACAUSE_icon from "../assets/LACAUSE_icon.png";
import { Auth } from "aws-amplify";
import { AnyARecord } from 'dns';

//ログインの状態を管理するContext
export const LoginStateContext = React.createContext({} as {
    state: Boolean
    setState: React.Dispatch<React.SetStateAction<boolean>>
})

//ログインの状態を管理するContext
export const userContext = React.createContext({} as {
    user: any | null
    setUser: any | null
})


export const idTokenContext = React.createContext({} as {
    idToken:string
    setiIdToken:any | null
})

//timestreamのクエリ状態を保持するためのContext
export const timestreamQueryContext = React.createContext<any>({} as {
    Provider: any | null,
    Consumer: any | null,
  });

//ログアウト処理関数
const Logout = () => {
    Auth.signOut();
  };


export const App = () => {
    //ログイン状態を管理するuseState
    const [user, setUser] = useState(null);

    //const [idToken, setiIdToken] = useState("");
    // 選択中のユーザーを管理するためのstate
    // 今回のサンプルでは初期値をベタ書きしていますが、実際にはAPIなどから取得したUser型の値が初期値になるはずです
    return(
    <>
    {/*フォント適用参考：https://www.kamo-it.org/blog/21/*/}
    <div>
        <link href="https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap" rel="stylesheet">
        </link>
    </div>
        {/*ログイン状態の管理*/}
        <userContext.Provider value={{ user, setUser }}>
            {/*遷移制御*/}
            <div>
            <Router>
            {/*固定の背景画像の設置*/}
            <div className={`App bg-gray-900 min-h-screen bg-cover bg-[url('https://vpgaccdemo-web.project-olive.info/img/bg.32224efa.png')]`}>

            {/*遷移制御*/}
                <ScrollToTop />
                <div>
                    <Routes>
                    {/*ログイン画面*/}
                    <Route path="/" element={<Login />}></Route>
                    {/*タブ画面*/}
                    <Route path="/tab" element={<TabComponents />}></Route>
                    </Routes> 
                </div>
            </div>
            </Router>
            </div>
        </userContext.Provider>
    </>
    );
};
