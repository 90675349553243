/*
上記に実装してるプログラムの解説
components
- TabComponents.tsx：タブを生成しているコンポーネント、このコンポーネントから各コンポーネントを呼び出している。
- DataExport.tsx：データエクスポート画面のコンポーネント
- AllUserListExport.tsx：cognitoの全てのユーザーをエクスポートしてくるコンポーネント
- RawDataExport.tsx：four_typesなど測定値をエクスポートするコンポーネント

グラフのドキュメント
https://recharts.org/en-US/guide/installation


*/
import React, { useState, useContext} from 'react';
import ReactDOM from 'react-dom/client';
//tailwind cssの適用に必要な記述がされたcssをimportしている。
import './style/index.css';
import { TabComponents } from './components/TabComponents';
//ローカルの画像を表示させるためのimport
import LACAUSE_icon from "./assets/LACAUSE_icon.png";
//グラフ関係のライブラリ
import { Amplify } from "aws-amplify";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { App } from './components/App';

///cognitoのユーザープールの情報を取得するための箇所
Amplify.configure({
  Auth: {
    identityPoolId: "ap-northeast-1:b94e1c1f-8c67-4500-8644-2c8c635d43ad",
    region: "ap-northeast-1",
    userPoolId: "ap-northeast-1_h6Wcjb8F7",
    userPoolWebClientId: "53hf72b9dltc4f4bc8rpngbt2f",
    oauth: {
      domain: "XXXXXXXXXXXXXX.auth.ap-northeast-1.amazoncognito.com",
      scope: ["openid"],
      redirectSignIn: "https://localhost:3000/",
      redirectSignOut: "https://localhost:3000/",
      responseType: "code",
    },
  
  },
});

//index.htmlのrootから要素を取得して指定
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


//最終表示に使用される返り値箇所
root.render(
  <>
    <App></App>    
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
