import { useEffect, useState, useContext } from "react";
import { Auth, Hub } from "aws-amplify";
import { Navigate } from "react-router-dom";
import logoImg from "./logo.svg";
import { userContext, timestreamQueryContext } from './App';
import LACAUSE_icon from "../assets/LACAUSE_icon.png";

//ログイン画面用
export const Login = () => {
  const {user, setUser} = useContext(userContext)
  //const [user, setUser] = useState(null);
  //const [state, setState] = useState<any | null>(null);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => setUser(userData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });
  }, []);

  useEffect(() => {
    getUser().then((userData) => setUser(userData));
  }, []);


  const getUser = async () => {
    try {
      //console.log('getuser ==========================')
      const userData = await Auth.currentAuthenticatedUser();
      //console.log(userData)
      // デバッグ用
      // Auth.currentSession().then((data) => {
      //   console.log(`token: ${data.getIdToken().getJwtToken()}`);
      // });
      return userData;
    } catch (e) {
      return console.log("Not signed in");
    }
  };

  const loginHandler = async () => {
    try {
      console.log('signin ==========================')
      //指定ユーザーのみでのログインを許可する
      if (username == "olive-ope-admin@01ive.co.jp") {
        const user = await Auth.signIn(username, password);

    
      } else {
        alert('ログイン権限がないユーザーです。');
      }
    } catch (error) {
      alert("signing in error");
    }

  };

  const inputChange = (event: any) => {
    const target = event.target;
    const id = target.id;
    switch (id) {
      case "password":
        setPassword(target.value);
        break;

      case "username":
        setUserName(target.value);
        break;

      default:
        break;
    }
  };
  

  return user ? (
    <Navigate to="/tab" />
  ) : (
    <>
    {/*ラクーズ画像*/}
    <img className='flex-none' src={LACAUSE_icon} />

    <div className="pt-10 max-w-md mx-auto">
      <form className="bg-gray-900 px-8 pt-6 pb-8 mb-4 text-white">
        <div className="logo py-10">
          <img
            src={logoImg}
            className="object-contain h-24 w-64 mx-auto"
            alt=""
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-white text-sm font-bold mb-2"
            htmlFor="username"
          >
            Username
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-900 border-gray-600 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="Username"
            onChange={inputChange}
          />
        </div>
        <div className="mb-6">
          <label
            className="block text-white text-sm font-bold mb-2"
            htmlFor="password"
          >
            Password
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-900 border-gray-600 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="********"
            onChange={inputChange}
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:bg-blue-200 focus:outline-none focus:shadow-outline"
            type="button"
            onClick={loginHandler}
            disabled={password == "" || username == "" ? true : false}
          >
            ログイン
          </button>
        </div>
      </form>
    </div>


    </>
  

    );
  }

    