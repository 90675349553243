import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export  const ScrollToTop = () => {
  //useLocationは現在のページのURLのpathやqueryなどの情報を取得できるもの
  //参考：https://zenn.dev/horisan/articles/2aeaf0bd3fb70f
  const { pathname } = useLocation();

  //遷移後、画面をtopにする処理
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};