/*
測定データをcsvでエクスポートできる画面

参考資料
https://dev.classmethod.jp/articles/implement-data-export-in-excel-or-csv-format-in-react-app/
*/

import React, { useState, useRef, forwardRef, useEffect, Suspense } from 'react';
import '../style/index.css';
//csvとexcelをDLできるようにするライブラリのimport
import ExcelJS from "exceljs";
//APIの認証に使用するコンポーネント
import { RawDataExport } from './RawDataExport';
import { AllUserAndCompanyInfoGet } from './AllUserAndCompanyInfoGet';
import { Company, CompanySelectProps } from "./AllUserAndCompanyInfoGet";
import dayjs, { Dayjs } from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { count } from 'console';
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { TimestreamQuery } from "@aws-sdk/client-timestream-query";

const userPoolId = process.env.REACT_APP_USER_POOLID_QUERY;
const clientId = process.env.REACT_APP_USER_POOLWEBCLIENTID_QUERY;
const identityPoolId = process.env.REACT_APP_IDENTITY_POOLID_QUERY;
const region = process.env.REACT_APP_AWS_REGION;
const username = process.env.REACT_APP_USER_NAME_QUERY;
const password = process.env.REACT_APP_PASSWORD;

// Cognito User Poolから認証情報を取得するプロバイダーを初期化
const poolData = {
  UserPoolId: userPoolId,
  ClientId: clientId,
};
const userPool = new CognitoUserPool(poolData);
const authenticationData = {
  Username: username,
  Password: password,
};
const authenticationDetails = new AuthenticationDetails(authenticationData);
const userData = {
  Username: username,
  Pool: userPool,
};
const cognitoUser = new CognitoUser(userData);


//エクスポートタブの中身になる関数
export const ExportTab = () => {
    //queryがどこでもできるように最初に認証する
    //データをxlsxかcsvのどちらでもDLできるようにする非同期関数
    const handlerClickDownloadButton = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        format: "xlsx" | "csv",
        /*
        後から入力された情報を渡す形式にする
        必要な引数をとりあえず記載
        */
        user_id_list:Array<string>,
        compnay_id:string,
        start_date:string,
        end_date:string
    ) => {

        console.log(start_date)

        e.preventDefault();

        //生成するcsvなどのworkbookを作成
        const workbook = new ExcelJS.Workbook();
              workbook.addWorksheet("sheet1");
        const worksheet = workbook.getWorksheet("sheet1");

        //生成したworksheetのヘッダーとキーを指定（カラムを作っているイメージ）
        worksheet.columns = [
        { header: "time", key: "time" },
        { header: "company", key: "company" },
        { header: "user_id", key: "user_id" },
        { header: "stress", key: "stress" },
        { header: "attention", key: "attention" },
        { header: "four_types", key: "four_types" }
        ];

        /*
        timestream dbからデータを取得する箇所
        */
        //resloveは成功した時の返り値を入れる、rejectは失敗時
        const worksheet_promise = new Promise((resolve, reject) => {
            //cognitoへの認証
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (result:any) => {
                    // 認証が成功した場合、Cognito Identity Poolから認証情報を取得する
                    const idToken = result.getIdToken().getJwtToken();
                    const cognitoIdentityClient = new CognitoIdentityClient({ region });
                    const logins = {
                        [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]: idToken,
                    };
                    //認証情報のcredentialを作成
                    const credentialProvider = fromCognitoIdentityPool({
                        client: cognitoIdentityClient,
                        identityPoolId: identityPoolId,
                        logins: logins,
                    });
                
                    // TimestreamQueryクライアントの初期化
                    const timestreamQuery = new TimestreamQuery({
                    region,
                    credentials: credentialProvider,
                    apiVersion: "2018-11-01",
                    });

                    //queryに使用するuser_id_listのstringを作るループ
                    let user_id_list_string:string = ""
                    for (const user_id of user_id_list) {
                    user_id_list_string = user_id_list_string + "'" + user_id + "',"            
                    };
                    
                    //最後の「,」は不要なので、最後の「,」を削除するcode
                    user_id_list_string = user_id_list_string.slice(0, -1);

                    //query_stringの生成
                    
                    /*
                    const query_string:string = `SELECT "time", "company", "user_id", "stress", "attention", "four_types"
                    FROM "prd_lacause_emotion_data_v1"."lacause_emotion"
                    WHERE "time" BETWEEN '${start_date}' AND '${end_date}'
                    AND "user_id" IN ( ${user_id_list_string} )`
                    */
                    
                    //timeの列を日本時間で出力するように＋9hしている(表示と抽出時刻共に)
                    const query_string:string = `SELECT DATE_ADD('hour', 9, "time") AS timestamp_with_9_hours_added,
                    "company", "user_id", "stress", "attention", "four_types"
                    FROM "prd_lacause_emotion_data_v1"."lacause_emotion"
                    WHERE DATE_ADD('hour', 9, "time") BETWEEN '${start_date}' AND '${end_date}'
                    AND "user_id" IN ( ${user_id_list_string} )`

                    console.log(query_string)

                    //結果が大量にある時にページネーションを実施し、全ての結果が得られるようにする関数箇所
                    const queryWithPagination = async (query_string:string) => {
                        //ページネーションのnextToken
                        let nextToken = null;
                        //取得できたrawデータを結合して保存しておく変数
                        let resultData:any = [];

                        //try catchでエラーをcatchできるように処理
                        try {
                            //whileでnextTokenがなくなるまでループ処理
                            while (true) {
                            //timestreamにqueryするパラメーターを指定
                            const params:any = {
                                QueryString: query_string,
                                NextToken: nextToken,
                            };
                            //timestreamにqueryを実施
                            const result:any = await timestreamQuery.query(params);
                            //取得できた結果を全て結合していく
                            resultData = resultData.concat(result.Rows)
                            //nextTokenの有無を判定し、ループを継続するか判定
                            if (!result.NextToken) {
                                break;
                            }
                            nextToken = result.NextToken;
                            }
                            //resultDataを関数の返り値とする
                            return resultData;
                        } catch (err) {
                            // エラーが発生した場合にエラーメッセを表示
                            console.error(err);
                        }
                    };
                    
                    //返り値はpromiseなので、promiseの返り値を受け取る
                    const timestream_query_promise = queryWithPagination(query_string);

                    //PromiseResultの値を取り出し、worksheetに追加
                    timestream_query_promise.then((result) => {   
                        console.log(result)
                    //スマートでないが、配列渡しの方法がわからないので、しゃーなしこの書き方
                        for (let i = 0; i < result.length; i++) {
                            //
                            worksheet.addRow({
                                time: result[i].Data[0].ScalarValue,
                                company: result[i].Data[1].ScalarValue,
                                user_id: result[i].Data[2].ScalarValue,
                                stress: parseInt(result[i].Data[3].ScalarValue,10),
                                attention: parseInt(result[i].Data[4].ScalarValue,10),
                                four_types: parseInt(result[i].Data[5].ScalarValue,10)
                            });
                        };

                        //データを挿入したworksheetを成功時の返り値とする
                        resolve(worksheet);
                    
                    }).catch((error) => {
                        //エラー処理
                        console.error(error);
                    });
            
                },
                //エラー処理
                onFailure: (err) => console.log(err),
            });
        });

        

        //worksheet_promiseの処理が終わってからDL処理を行うためにthenを使用
        worksheet_promise.then(async () => {
            //ダウンロードするデータ生成箇所
            const uint8Array =
            format === "xlsx"
                        ? await workbook.xlsx.writeBuffer() //xlsxの場合
                        : await workbook.csv.writeBuffer(); //csvの場合
            const blob = new Blob([uint8Array], { type: "application/octet-binary" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            //jstの現在時刻を取得
            //const now_time_str = Date();
            //jstの現在時刻を取得
            const now_time = dayjs().add(0, 'h');
            const now_time_str = `${now_time.year()}-${Number(now_time.month()) + 1}-${now_time.date()}`;
            //現在時刻をファイル名に指定（後からもう少しいい感じに変更する）
            a.download = company_id + '_' + now_time_str + "_JST."+ format; //フォーマットによってファイル拡張子を変えている
            a.click();
            a.remove();
          }).catch((error) => {
            //rejectメソッドで返されたエラーを処理する
          });
    };


    /*
    //データリクエスト時に使用するペイロードをここで指定
    const payload = JSON.stringify({
        //APIデータの取得に使用するuser_idを指定
        user_id: "edu_poc_g4_021@project-olive.info",
        //APIデータを取得するcompany_idを指定
        company: "edu_poc_g4",
        //データ取得期間を指定（始め、終わり）
        start: "2022-12-12 08:30:00",
        end: "2022-12-12 09:00:00",
        //データの取得期間を指定
        period: 60,
    });
    */

    //選択されたcompany情報を保持するuseState
    const [company, setCompany] = useState<Company | null>({
        id: '' ,
        name:'info_get'
      });

    //選択されたuser情報を保持するuseState（propsとして使用する）
    const [get_user_list, setGet_user_list] = useState<Array<string>>([""]);

    //選択しているcompany_idを渡すためのuseState
    const [company_id, setCompany_id] = useState('');

    //無限ループにならないように、useEffectで処理
    useEffect(() => {
        if (typeof company?.id === "string"){
            setCompany_id(company?.id)
        }
    },[company])

    //時刻選択に使用する変数の一括型定義
    interface end_start_array_interface {
        //日時選択に使用するuseState
        start_date_string: string;
        end_date_string:string;
        //取得したい時刻のスタートを入れるuseState
        start_date:Dayjs | null;
        start_time:string | null;
        //終了時刻
        end_date:Dayjs | null;
        end_time:string | null;
      }

 
    const [end_start_array, setEnd_start_array] = useState<end_start_array_interface>({
        start_date_string: `${dayjs().year()}-${Number(dayjs().month()) + 1}-${dayjs().date()}`,
        end_date_string:`${dayjs().year()}-${Number(dayjs().month()) + 1}-${dayjs().date()}`,
        start_date:dayjs(),
        start_time:'00:00',
        end_date:dayjs(),
        end_time:'00:00',
    })

    //変更関数
    const ChangeStartdate = (newValue: Dayjs | null) => {
        //取得したい日付
        setEnd_start_array((prev) => ({ ...prev, start_date: newValue }));
        //日時選択に使用するdate
        const StartdateString = `${newValue?.year()}-${Number(newValue?.month()) + 1}-${newValue?.date()}`;
        setEnd_start_array((prev) => ({ ...prev, start_date_string: StartdateString }));
        };

    const ChangeStarttime = (newValue: string | null) => {
        setEnd_start_array((prev) => ({ ...prev, start_time: newValue + ':00' }));
        };
    
    const ChangeEnddate = (newValue: Dayjs | null) => {
        //取得したい日付
        setEnd_start_array((prev) => ({ ...prev, end_date: newValue }));
        //日時選択に使用するdate
        const EnddateString = `${newValue?.year()}-${Number(newValue?.month()) + 1}-${newValue?.date()}`;
        setEnd_start_array((prev) => ({ ...prev, end_date_string: EnddateString}));
        };

    const ChangeEndtime = (newValue: string | null) => {
        setEnd_start_array((prev) => ({ ...prev, end_time: newValue + ':00' }));
        };

    
    return (
    <>

        {/*左右上下とマージンを取るためのdiv*/}
        <div className = "m-5">



        {/*会社とユーザーの選択コンポーネント箇所*/}
        <div className='my-3 text-2xl text-gray-100' >selected company id: {company?.name}</div>
        <AllUserAndCompanyInfoGet
            setCompany={setCompany}
            selected={company}
            setGet_user_list={setGet_user_list}
        />


        {/**ダウンロードしたい時間範囲の入力箇所*/}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div>
                <Stack spacing={1}>
                {/*要素を横に並べるcss*/}
                <div className='flex ...'>
                    {/*データ取得開始日付*/}
                    <h1 className="mr-3 flex items-center text-2xl text-gray-100 font-['Arial']">Start Date</h1>
                    {/*データ取得開始日付選択箇所*/}
                    <div className='my-3 mr-3 flex-none'>
                    <DesktopDatePicker
                        className="bg-gray-300 hover:bg-gray-100 text-gray-100 rounded-lg"
                        inputFormat="YYYY/MM/DD"
                        value={end_start_array.start_date}
                        onChange={ChangeStartdate}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    </div>

                    {/*データ取得開始時刻*/}
                    <h1 className="mr-3 flex items-center text-2xl text-gray-100 font-['Arial']">Time</h1>
                    {/*データ取得開始時刻選択箇所*/}
                    <div className='my-3 mr-3 flex-none'>
                    <TextField
                            className="bg-gray-300 hover:bg-gray-100 text-gray-100 rounded-lg"
                            id="time"
                            type="time"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                            sx={{ width: 150 }}
                            value={end_start_array.start_time}
                            onChange={(event) => ChangeStarttime(event.target.value)}
                            />
                    </div>
                </div>
                </Stack>
            </div>


            <div>
                <Stack spacing={1}>
                {/*要素を横に並べるcss*/}
                <div className='flex ...'>
                {/*データ取得終了日付*/}
                <h1 className="mr-5 flex items-center text-2xl text-gray-100 font-['Arial']">End Date</h1>
                    {/*データ取得開始日付選択箇所*/}
                    <div className='my-3 mr-3 flex-none'>
                    <DesktopDatePicker
                        className="bg-gray-200 hover:bg-gray-100 text-white rounded-lg"
                        inputFormat="YYYY/MM/DD"
                        value={end_start_array.end_date}
                        onChange={ChangeEnddate}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    </div>

                    {/*データ取得終了時刻*/}
                    <h1 className="mr-3 flex items-center text-2xl text-gray-100 font-['Arial']">Time</h1>
                    {/*データ取得終了時刻選択箇所*/}
                    <div className='my-3 mr-3 flex-none'>
                    <TextField
                            className="bg-gray-200 hover:bg-gray-100 text-white rounded-lg"
                            id="time"
                            type="time"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                                readOnly: false
                            }}
                            sx={{ width: 150 }}
                            value={end_start_array.end_time}
                            onChange={(event) => ChangeEndtime(event.target.value)}
                            />
                    </div>
                    </div>

                </Stack>
            </div>
        </LocalizationProvider>



        {/*ダウンロードボタン*/}
        <div className="flex flex-row-reverse ...">
        {/*xlsxボタン*/}
            <div className="m-1 flex-none">
                <button className="bg-green-700 hover:bg-green-600 text-gray-100 rounded px-4 py-2"
                onClick={(e) => 
                    handlerClickDownloadButton(
                        e,
                        "xlsx",
                        get_user_list,
                        company_id,
                        end_start_array.start_date_string + ' ' + end_start_array.start_time,
                        end_start_array.end_date_string + ' ' + end_start_array.end_time,
                    )}>
                    Excel形式
                </button>
            </div>
        
        {/*csvボタン*/}
            <div className="m-1 flex-none">
                <button className="bg-green-500 hover:bg-green-300 text-gray-100 rounded px-4 py-2"
                    onClick={(e) => 
                    handlerClickDownloadButton(
                        e,
                        "csv",
                        get_user_list,
                        company_id,
                        end_start_array.start_date_string + ' ' + end_start_array.start_time,
                        end_start_array.end_date_string + ' ' + end_start_array.end_time,
                    )}>
                    CSV形式
                </button>
            </div>
        </div>



        </div>

    







    </>
    );


};