import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import React from 'react'
import { Auth, Hub } from "aws-amplify";
import { useState, useEffect, useCallback, useContext } from "react";
import { Link, Navigate } from "react-router-dom";
//ExportTabのコンポーネントをimportする
import { ExportTab } from './ExportTab';
import LACAUSE_icon from "../assets/LACAUSE_icon.png";
//HomeTabのコンポーネントをimportする
import { PieChartTab } from './PieChartTab';
import { PersonalReport } from './PersonalReport';
import { userContext } from './App';


import { Sdk } from './Sdk';

//タブに表示する用の日本語リスト
const tablistname: Array<string> = ['Home', 'Report', 'Export', 'PieChart', 'SDK', 'PersonalReport'];


const Logout = () => {
    Auth.signOut();
  };


export const TabComponents = () => {
    //ログイン状態を管理するContext
    const {user, setUser} = useContext(userContext);

    //ログイン情報がない場合は、ログイン画面に遷移させる
    return !user ? (
        <Navigate to="/" />
    ):(
        <>

        <div className='flex ...'>
            {/*ラクーズ画像*/}
            <img className='mb-4 flex-none' src={LACAUSE_icon} />
            {/*間の余白確保用*/}
            <div className="grow h-14 ...">
            </div>
            {/*ログアウトボタン*/}
            <div onClick={Logout} className="flex-none w-30 h-12 ... m-4 py-3 px-3 font-bold text-gray-100 bg-gray-700 rounded-md
            hover:bg-gray-500">
            Logout
            </div>
        </div>
        
        <Tabs>
            <TabList>
                {/* 
                <Tab>{tablistname[0]}</Tab>
                <Tab>{tablistname[1]}</Tab>
                */}
                <Tab>{tablistname[2]}</Tab>
                {/*
                <Tab>{tablistname[3]}</Tab>
                <Tab>{tablistname[4]}</Tab>
                <Tab>{tablistname[5]}</Tab>
                 */}
            </TabList>

                {/* 
                <TabPanel>
                    <h1>HOMEです</h1>
                    <HomeTab></HomeTab>
                </TabPanel>
                <TabPanel>
                    <h1>Aboutです</h1>
                </TabPanel>
                */}
                <TabPanel>
                    <ExportTab></ExportTab>
                </TabPanel>
                {/*
                <TabPanel>
                    <PieChartTab></PieChartTab>
                </TabPanel>
                
                <TabPanel>
                    <Sdk></Sdk>
                </TabPanel>

                <TabPanel>
                    <PersonalReport></PersonalReport>
                </TabPanel>
                 */}
                
        </Tabs>
        </>
    );
};



//参考記事：https://dev-k.hatenablog.com/entry/react-tabs-hatenablog
