/*
cognitoの全ユーザーを返すAPIを叩くコンポーネント


objectにfillterの参考記事
https://qiita.com/fizumi6/items/4e1588343df0ff46d145

*/

import '../style/index.css';
import { Auth, Amplify } from "aws-amplify";
//selectボタンを実装するライブラリ
import Select from 'react-select';
import React, { useMemo, useState, useEffect } from "react";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { TimestreamQuery } from "@aws-sdk/client-timestream-query";

const userPoolId = process.env.REACT_APP_USER_POOLID_QUERY;
const clientId = process.env.REACT_APP_USER_POOLWEBCLIENTID_QUERY;
const identityPoolId = process.env.REACT_APP_IDENTITY_POOLID_QUERY;
const region = process.env.REACT_APP_AWS_REGION;
const username = process.env.REACT_APP_USER_NAME_QUERY;
const password = process.env.REACT_APP_PASSWORD;

// Cognito User Poolから認証情報を取得するプロバイダーを初期化
const poolData = {
  UserPoolId: userPoolId,
  ClientId: clientId,
};
const userPool = new CognitoUserPool(poolData);
const authenticationData = {
  Username: username,
  Password: password,
};
const authenticationDetails = new AuthenticationDetails(authenticationData);
const userData = {
  Username: username,
  Pool: userPool,
};
const cognitoUser = new CognitoUser(userData);

//ロードがture・falseを管理するuseState
//const [loading, setLoading] = useState(true); //最初にloading出したいのでtrue
//const [pokemonData, setPokemonData] = useState([]);
//データ突っ込む
//const [Data, setData] = useState([]);

//cognito関連
//環境変数からの読み込みエラーの解決方法参考
//https://hagaahiro.hatenablog.com/entry/2022/01/06/231848
//機密情報は環境変数から呼び出して使用する






//xlsxかcsvでデータをDLする際のデータの中身の配列を作成して返す関数
/*
下記のような配列を返り値として生成している
[
    {
    time: "f001",
    company: 1629902208,
    user_id: "りんご",
    four_types: 
    },
    {
    time: "f001",
    company: 1629902208,
    user_id: "りんご",
    four_types: 
    }
]
*/


//APIのレスポンスを受け取るためのcompany型を作成する
export type Company = {
  id: string;
  name: string;
};

//コンポーネントのProps。初期値と選択時の関数を渡す
//選択したcompany情報を入れるPorpsの型
export type CompanySelectProps = {
  selected: Company | null;
  setCompany: (company: Company | null) => void;
  setGet_user_list: React.Dispatch<React.SetStateAction<string[]>>;
};

//APIのJSONレスポンスに合わせた型
//APIのデータ受け取りに使用する型
export type ApiDataReceivingType = {
  company: string;
  email: string;
  subjectId2: string;
};

// 選択肢を react-select にわたすための型。labelとvalueに変更する。
//react-selectでは、型として、「label」と「value」が必要なので、それ用の型にしている。
export type CompanyOption = {
  label: string;
  value: string;
};

//CompanyOption型をCompany型に変換します。react-select から渡されたデータをアプリで扱うために使います
//const increment = (num: number): number => num + 1;
//アロー関数で上記のような記載の場合、:numberは戻り値の型宣言になる。
const convertToCompany = 
  (args: CompanyOption | null): Company | null => 
    {
      if (!args) return null;
      return {  
        id: args.label,
        name: args.value,
      };
    }

//Company型をComapnyOption型に変換します。
//react-selectへデータを渡すときに変換します。
const convertToComapnyOption = (company: Company): CompanyOption =>
    {
      //{}だと変数定義などして戻り値を作成することができる。
      return{
        label: company.id,
        value: company.name,
      };
    }

//APIから取得したデータを元に、セレクトボタン等を作成する関数
//関数の書き方が少しピンときていない、、、
export const AllUserAndCompanyInfoGet:React.FC<CompanySelectProps> = 
 ({setCompany, selected, setGet_user_list}) => 
  //({setCompany, selected}) => 
  {
    const value = useMemo(
      () => (selected ? convertToComapnyOption(selected) : null),
    [selected]
    );
    //参考になりそうなページ
    //https://stackoverflow.com/questions/63696310/how-to-use-typescript-with-the-definition-of-custom-styles-for-a-select-using-re

    //company（会社）リストのセレクトボタン用のuseState
    const [company_id, setCompany_id] = useState<Company[]>(
      [
      { id: '現在、会社情報を取得中です。' ,
        name:'info_get'}
      ]
    );

    //userlistのセレクトボタン用のuseState
    const [userlist, setuserlist] = useState<Array<string>>(
      []
    );
    
    //resultにはfetchで取得したAPIレスポンスが入っている
    //const result = LoginAndFetchData(USER_NAME, PASSWORD);
    //selectの選択肢を入れる配列を先に型宣言
    //型は独自に作成した、Companyを指定
    const unique_company_list:Company[] = []

    //useEffect参考
    //https://qiita.com/seira/items/e62890f11e91f6b9653f
    useEffect(() => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result:any) => {
          // 認証が成功した場合、Cognito Identity Poolから認証情報を取得する
          const idToken = result.getIdToken().getJwtToken();
          const cognitoIdentityClient = new CognitoIdentityClient({ region });
          const logins = {
            [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]: idToken,
          };
          const credentialProvider = fromCognitoIdentityPool({
            client: cognitoIdentityClient,
            identityPoolId: identityPoolId,
            logins: logins,
          });
      
          // TimestreamQueryクライアントの初期化
          const timestreamQuery = new TimestreamQuery({
            region,
            credentials: credentialProvider,
            apiVersion: "2018-11-01",
          });

          const queryWithPagination = async (query_string: string) => {
            let nextToken = null;
            let resultData:any = []; // 結果のデータを保持する変数を初期化
          
            try {
              while (true) {
                const params: any = {
                  QueryString: query_string,
                  NextToken: nextToken,
                };
                const result: any = await timestreamQuery.query(params);
          
                // rawデータをとりあえず入れる
                // ここで result.Rows などを処理する
                resultData = resultData.concat(result.Rows)
          
                if (!result.NextToken) {
                  break;
                }
                nextToken = result.NextToken;
              }

              console.log(resultData)
          
              // resultDataを関数の返り値として返す
              return resultData;
            } catch (err) {
              console.error(err); // エラーが発生した場合の処理
            }
          };

          //query_stringの生成（companyのリストを取得してくる）
          let query_string:string = `SELECT DISTINCT "company"
          FROM "prd_lacause_emotion_data_v1"."lacause_emotion"`
          
          //返り値はpromiseなので、promiseの返り値を受け取る
          let timestream_query_promise = queryWithPagination(query_string);

          // PromiseResultの値を取り出す
          timestream_query_promise.then((result) => {

            const company_list:Company[] = []
            const keys = Object.keys(result);
            for (const key of keys) {
              unique_company_list.push({ id: result[key].Data[0].ScalarValue , name: result[key].Data[0].ScalarValue },)
            }
            //sortして代入（選択肢になる変数）
            setCompany_id(unique_company_list.sort((a, b) => {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            }));
          }).catch((error) => {
            //エラー処理
            console.error(error);
          });

          //query_stringの生成（選択中のuser_listのリストを取得してくる）
          query_string = `SELECT DISTINCT "user_id"
          FROM "prd_lacause_emotion_data_v1"."lacause_emotion"
          WHERE "company" = '${selected?.name}'`

          //返り値はpromiseなので、promiseの返り値を受け取る
          timestream_query_promise = queryWithPagination(query_string);

          // PromiseResultの値を取り出す
          timestream_query_promise.then((result) => {

            const company_user_list_string:Array<string> = []
            const keys = Object.keys(result);
            for (const key of keys) {
              company_user_list_string.push(result[key].Data[0].ScalarValue)
            }

            setuserlist(company_user_list_string)

            
          }).catch((error) => {
            //エラー処理
            console.error(error);
          });

        },
        onFailure: (err) => console.log(err),
      });
    //,[selected]で、ユーザーがcompany_idを変更する度に処理を走らせるようにする
    },[selected]);

    //setCompanyの値を変更する処理
    const onChange = (
      newCompany: CompanyOption | null) => 
    {
      setCompany(convertToCompany(newCompany));
    }

    //チェックボックスが入っているユーザーのメールアドレスの取り出し関数
    const useronChange = () => {
      //チェックボックスの配列をhtmlから取り出す
      let allCheckBoxes = document.querySelectorAll("input[type='checkbox']") as NodeListOf<HTMLInputElement>;
      //チェックが入っているユーザーのメールアドレスのリスをを作成している
      let userlistChecked = Array.prototype.map.call(allCheckBoxes,
        function(checkBox) {
        //checkBoxの情報で、なおかつチェックが入っている中身だけreturnする
        if(checkBox && checkBox.checked) {
          return checkBox.value
        }
      }).filter(Boolean) as string[];

      //親に受け渡すuseStsteにチェックが入ったユーザーリスを渡す
      setGet_user_list(userlistChecked)
    };

    //全てのユーザーを選択するボタンの要素抽出
    let checkall = document.getElementById("checksAll")! as HTMLInputElement;

    //全てのチェックボックスをonにしてユーザー情報を渡す関数
    const useronAllSelect = () => {
      //チェックボックスの配列をhtmlから取り出す
      let allCheckBoxes = document.querySelectorAll('[name="checks"]');

      //チェックが入っているユーザーのメールアドレスのリスをを作成している
      let userlistChecked = Array.prototype.map.call(allCheckBoxes,
        function(checkBox) {
          //全選択ボタンがonの時、offのチェックボックスユーザーはonにして、onのユーザーはそのままにする処理
          checkall.checked == true ? checkBox.checked = true : checkBox.checked = false;
          //全てのユーザーのメールアドレスを返す
          return checkBox.value
      }).filter(Boolean) as string[];

      //親に受け渡すuseStsteにチェックが入ったユーザーリスを渡す
      setGet_user_list(userlistChecked)
    };

    let allCheckBoxes = document.querySelectorAll('[name="checks"]');

    //全選択のon/offを実現するための変数
    //参考：https://qiita.com/engineerhikaru/items/714d27fe6f339b34c909
    const [checks, setChecks] = useState<NodeListOf<Element>>();

    useEffect(()=>{
          //全選択のon/offを実現するための変数
          setChecks(document.querySelectorAll('[name="checks"]')!);
    }
    ,[userlist]);

    company_id.map((item) => (console.log(item['id'])))

    return(
        <div>
        {/*selectボタンの作成*/}
        <Select
              className='w-1/3'
              instanceId="userSelect"
              value={value} // 選択中の値
              onChange={onChange} // 選択されたときにはしる処理
              options={company_id.map(convertToComapnyOption)} // 選択肢
            />

        {/*userlistからチェックボックスを生成する箇所*/}

        {(() => {
          if(userlist.length !== 0) {
            return(
              <>
            <h1 className='text-white'>データをエクスポートしたいユーザーを選択してください。</h1>

            {/*全てのチェックボックスのon/off機能*/}
            <div>
              <input  
                className=''
                id='checksAll'
                type="checkbox"
                value='all_select'
                onChange={useronAllSelect}
              />
              <label 
                className='ml-2 text-2xl font-medium text-gray-900 dark:text-gray-300'
                htmlFor='checksAll'>
                Select all
              </label>
            </div>
          </>
            );
          }
        })()}

        {userlist.sort().map((x) => {
          return(
            <div>
              <div key={x}>
              <input
                className='mr-1'
                id="checks"
                type="checkbox"
                value={x}
                onChange={useronChange}
                name="checks"
                key={x}
              />
              
              {/*チェックボックス横の文字（ここではメールアドレス）を表示するhtml*/}
              {/*ここでなんか表示がおかしくなる何かが発生している。*/}
              {/*ml-3 text-1xl font-medium text-gray-900 dark:text-gray-300*/}
              <label className='text-white'>
                {x}
              </label>
              </div>
            </div>
          );
        })}




      
        </div>
    );
}


